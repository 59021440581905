import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import Twentytwenty from 'react-twentytwenty';
import Img from 'gatsby-image';
import LeftColContent from 'src/components/desktop/leftColContent/leftColContent';
import { SlideLarge } from '../../_styles';
import {
  Slider, Circle, RightArrow, LeftArrow, RightLabel, LeftLabel, TTContainer, SidePanel, pulseLeft, pulseRight,
} from './styles';


export const SliderWithCircle = ({ leftLabel, rightLabel }) => (
  <Slider>
    <Circle>
      <LeftArrow
        initial="enter"
        animate="animate"
        variants={pulseLeft}
      />
      <RightArrow
        initial="enter"
        animate="animate"
        variants={pulseRight}
      />
      <MDXProvider components={{
        p: (props) => <p {...props} tabIndex="0" />,
      }}
      >
        <LeftLabel>
          <MDXRenderer>
            {leftLabel}
          </MDXRenderer>
        </LeftLabel>
        <RightLabel>
          <MDXRenderer>
            {rightLabel}
          </MDXRenderer>
        </RightLabel>
      </MDXProvider>
    </Circle>
  </Slider>
);


const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    before: file(relativePath: { eq: "chapter_3/sub_3/beforewwi.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
    },
    after: file(relativePath: { eq: "chapter_3/sub_3/afterwwi.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
    },
  }
`);
  return (
    <SlideLarge>
      <div css={{ display: 'flex', height: '100%' }}>
        <TTContainer>
          <Twentytwenty
            left={<Img fluid={assetQuery.before.childImageSharp.fluid} />}
            right={<Img fluid={assetQuery.after.childImageSharp.fluid} />}
            slider={<SliderWithCircle leftLabel={query.before.body} rightLabel={query.after.body} />}
          />
        </TTContainer>
        <SidePanel>
          <LeftColContent body={query.description.body} style={{ paddingLeft: '14%' }} />
        </SidePanel>
      </div>
    </SlideLarge>
  );
};


export default Slide;
