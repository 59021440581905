import React from 'react';
import Slide1 from 'src/slides/desktop/hu/chapter_3/sub_3/slide1';

import WrapperMobile from 'src/slides/mobile/hu/chapter_3/sub_3/wrapperMobile';
import { isMobile } from 'react-device-detect';
import SEO from 'src/components/_shared/seo';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

const allSlides = [<Slide1 />];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Európa az I. világháború előtt és után | A varsói csata" lang="hu" description="Nézd meg, milyen államok jöttek létre Európában az I. világháború után." />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Mobile = () => (
  <>
    <SEO title="Európa az I. világháború előtt és után | A varsói csata" lang="hu" description="Nézd meg, milyen államok jöttek létre Európában az I. világháború után." />
    <WrapperMobile />
  </>
);

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
